import { bootstrapApplication } from '@angular/platform-browser';
import { UpdateNotifierService } from '@app/core/services/update-notifier.service';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .then((ref) => {
    const updateNotifier = ref.injector.get(UpdateNotifierService);
    updateNotifier.init();
  })
  .catch((err) => console.error(err));
